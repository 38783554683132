let isMetaMaskInstalled;

window.addEventListener("load", function() {
	if (typeof window.ethereum !== "undefined") {
		console.log("web3 is enabled");
		if (window.ethereum.isMetaMask === true) {
			console.log("MetaMask is active");
			isMetaMaskInstalled = true;
		} else {
			console.log("MetaMask is not available");
			isMetaMaskInstalled = false;
		}
	} else {
		isMetaMaskInstalled = false;
		console.log("web3 is not found");
	}
});
